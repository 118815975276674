import React, { useState } from 'react';
import {
  List,
  Datagrid,
  TextField,
  Show,
  Filter,
  Pagination,
  SimpleShowLayout,
  ReferenceInput,
  ReferenceArrayInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  CheckboxGroupInput,
  SelectInput,
  ReferenceField,
  AutocompleteInput,
  Create,
  Edit,
  SimpleForm,
  BooleanField,
  BooleanInput,
  DateField,
  TextInput,
  required,
  ShowButton,
  FormDataConsumer,
  useRecordContext,
  DateTimeInput,
  DateInput,
  NumberInput,
  RichTextField,
  Button
} from 'react-admin';
import { Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import AssignmentIcon from '@mui/icons-material/Assignment';
import WarningIcon from '@mui/icons-material/Warning';
import { RichTextInput } from 'ra-input-rich-text';
import CryptoJS from 'crypto-js';

/*import { DateTimeInput } from 'react-admin-date-inputs';*/
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

const EXPIRING_DAYS = 30;

const licenseStyles = makeStyles({
  valid: { background: '#9eff8f' },
  expire_soon: { background: '#ffef63' },
  expired: { background: '#ff8ea3' },
  disabled: { background: '#aaaaaa' },
});

function getLicenseStyleClass(date, option, disabled = false) {
  const licenseDate = new Date(date);
  const now = new Date();
  const expireSoon = new Date();
  expireSoon.setDate(now.getDate() + EXPIRING_DAYS);

  if (option === 'disabled') {
    return disabled;
  }
  if (option === 'valid') {
    return licenseDate > expireSoon;
  }
  if (option === 'expire_soon') {
    return licenseDate > now && licenseDate < expireSoon;
  }
  if (option === 'expired') {
    return licenseDate < now;
  }

  return false;
}

const hexToBytes = (hex) => {
    for (var bytes = [], c = 0; c < hex.length; c += 2)
        bytes.push(parseInt(hex.substr(c, 2), 16));
    return bytes;
}

const LicenseField = (props) => {
	const record = useRecordContext();
	return(<div className="license-box">
		<span className="license-cool-icon"><AssignmentIcon fontSize="large" /></span>
		<div className="license-tools">
		<Button variant="contained" startIcon={<ContentCopyIcon />} label="Copy license to clipboard" onClick={()=>{navigator.clipboard.writeText(record["token"]);}} />
		<Button variant="contained" startIcon={<SaveAltIcon />} label="Save license to file" onClick={()=>{
			const link = document.createElement("a");
			const file = new Blob([record["token"]], { type: 'application/experimental' });
			link.href = URL.createObjectURL(file);
			link.download = "License " + record["sn"] + " (ACTIVE).lcs";
			link.click();
			URL.revokeObjectURL(link.href);
		}} />
		</div>
		<div>{ record["token"] }</div>
	</div>)
}

const DecryptHWID = (hwid) => {
	var ciphertext = hwid;
	if (!ciphertext)
		return { ok: null, txt: "" };
	
	var iv = "MeatestFortes42+"; // 16-byte IV
	var passphrase = "MeatestDecryptKey456++";	
	
	// Convert IV and passphrase to WordArray
	var ivBytes = CryptoJS.enc.Utf8.parse(iv);
	
	var key = CryptoJS.PBKDF2(passphrase, "\0\0\0\0\0\0\0\0", { keySize: 256/32, iterations: 1000 });
	var ciphertextBytes = CryptoJS.enc.Base64.parse(ciphertext);

	// Decrypt ciphertext
	var decrypted = CryptoJS.AES.decrypt({
		ciphertext: ciphertextBytes
	}, key, {
		iv: ivBytes,
		padding: CryptoJS.pad.Pkcs7,
		mode: CryptoJS.mode.CBC
	});
	
	// Convert decrypted ciphertext to plaintext
	try
	{
		var plaintext = CryptoJS.enc.Utf8.stringify(decrypted);	
		var pretty = JSON.stringify(JSON.parse(plaintext),null,2);
	
		return { ok: true, txt: pretty };
	}
	catch (ex) { return { ok: false, txt: ex.message }; }
}

const HwkeyField = (props) => {
	const record = useRecordContext();
	
	var ciphertext = record["hwid"];
	
	/*if (!ciphertext)
		return (<div></div>);
	
	var iv = "MeatestFortes42+"; // 16-byte IV
	var passphrase = "MeatestDecryptKey456++";	
	
	// Convert IV and passphrase to WordArray
	var ivBytes = CryptoJS.enc.Utf8.parse(iv);
	
	var key = CryptoJS.PBKDF2(passphrase, "\0\0\0\0\0\0\0\0", { keySize: 256/32, iterations: 1000 });
	var ciphertextBytes = CryptoJS.enc.Base64.parse(ciphertext);

	// Decrypt ciphertext
	var decrypted = CryptoJS.AES.decrypt({
		ciphertext: ciphertextBytes
	}, key, {
		iv: ivBytes,
		padding: CryptoJS.pad.Pkcs7,
		mode: CryptoJS.mode.CBC
	});

	// Convert decrypted ciphertext to plaintext
	try
	{
		var plaintext = CryptoJS.enc.Utf8.stringify(decrypted);	
		var pretty = JSON.stringify(JSON.parse(plaintext),null,2);
	
		return (<div>
			<div className="hwkey-decoded">{ pretty }</div>
			<span className="hwkey-raw">{ciphertext}</span>
		</div>);
	}
	catch (ex) { return (<div><div>{ex.message}</div><span className="hwkey-raw">{ciphertext}</span></div>) }*/
	
	var dec = DecryptHWID(ciphertext);
	if (!dec)
		return (<div style={{color: "red"}}>Unspecified error while decrypting!</div>);	
	if (dec.ok === null)
		return (<div style={{color: "red"}}>No key specified!</div>);
	if (!dec.ok)
		return (<div><div style={{color: "red"}}>ERROR: {dec.txt}</div><span className="hwkey-raw">{ciphertext}</span></div>);
	return (<div>
			<div className="hwkey-decoded">{ dec.txt }</div>
			<span className="hwkey-raw">{ciphertext}</span>
		</div>);
}

const ColoredDateField = (props) => {
  const classes = licenseStyles();
  const record = useRecordContext();
  return (
    <DateField
      className={classnames({
        [classes.valid]: getLicenseStyleClass(record[props.source], 'valid'),
        [classes.expire_soon]: getLicenseStyleClass(record[props.source], 'expire_soon'),
        [classes.expired]: getLicenseStyleClass(record[props.source], 'expired'),
        [classes.disabled]: getLicenseStyleClass(record[props.source], 'disabled', !record['enabled']),
      })}
      {...props}
    />
  );
};

const LicenseFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput source="client" reference="clients" allowEmpty alwaysOn>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
	<ReferenceArrayInput source="type" reference="apps" allowEmpty alwaysOn>
      <CheckboxGroupInput optionText="name" />
    </ReferenceArrayInput>
    <ReferenceInput label="Hardware ID" source="id" reference="licenses" allowEmpty>
      <AutocompleteInput optionText="hwid" />
    </ReferenceInput>
    <BooleanInput label="Enabled" source="enabled" defaultValue="true" />
  </Filter>
);

const LicensePagination = (props) => (
  <Pagination rowsPerPageOptions={[50, 100, 200, 500]} {...props} />
);

export const LicenseList = (props) => (
  <List
    {...props}
    filters={<LicenseFilter />}
    pagination={<LicensePagination />}
    perPage={50}
  >
    <Datagrid>
      <TextField source="id" />
	  <BooleanField source="enabled" />	  
      <ColoredDateField source="expiration" />
	  <RichTextField source="sn" label="Serial number" />
	  <ReferenceField source="client" reference="clients">
		<TextField source="name" />        
      </ReferenceField>
	  <ReferenceArrayField source="type" reference="apps">
		<SingleFieldList>
			<ChipField source="name" />
		</SingleFieldList>
	  </ReferenceArrayField>
	  <TextField source="person" />    
	  
      <TextField source="users" />	  
	  <TextField source="offint" label="Off. int." />	        
	  <DateField source="created" />
      <ShowButton />
    </Datagrid>
  </List>
);

const hwkeyValidityCheck = (value, allValues) => {
	if (!value)
		return undefined;
	var dec = DecryptHWID(value);
	if (!dec)
		return "Invalid key: Unknown error";
	if (!dec.ok)
		return "Invalid key: " + dec.txt;
	return undefined;
}

export const LicenseCreate = (props) => (
  <Create {...props} redirect="show" mutationMode="pessimistic">
    <SimpleForm mode="all" reValidateMode="all">
	{ /* <SelectInput
        source="type"
        choices={[
          { id: 'server', name: 'WinQBase Server' },
          { id: 'caliber', name: 'Caliber' },
          { id: 'all', name: 'WinQBase Server + Caliber' },
        ]} /> */}
		
	  <ReferenceArrayInput source="type" reference="apps">
        <CheckboxGroupInput optionText="name" />
      </ReferenceArrayInput>
	  
      <NumberInput 
        source="users"
        validate={required()}
		defaultValue={10}
      />
	  <NumberInput 
		label="Offline interval (days)"
        source="offint"
        validate={required()}
		defaultValue={7}
      />
      <TextInput source="hwid" resettable label="Hardware ID" validate={hwkeyValidityCheck} />
	  
      <ReferenceInput source="client" reference="clients" debounce={0}>
        <AutocompleteInput debounce={0} optionText="name" />
      </ReferenceInput>	  
	  <DateInput
		defaultValue={new Date('2038-01-01')}
        source="expiration"
        validate={required()}
        options={{
		  format: 'dd/MM/yyyy',
          ampm: false,
          clearable: true,
        }}
		  />		
		  
      <BooleanInput source="enabled" defaultValue={true} />
	  <TextInput source="person" label="Contact person" />
	  <RichTextInput source="description" label="Description (for admin only)" />
    </SimpleForm>
  </Create>
);

export const LicenseEdit = (props) => { 

	const [edit1, setEdit1] = useState(false);

	return (
  <Edit {...props} redirect="show" mutationMode="pessimistic">
    <SimpleForm mode="all" reValidateMode="all">
		<div className="license-edit-warning">		
			<span className="warn-ico"><WarningIcon /></span>
			<h1>Warning!</h1>
			Editing any of the fields marked with <span style={{color:"red"}}>red border</span> will re-generate a new license key, invalidating the old one! If that is not what you want, simply <a href="/#/licenses/create">generating a new license</a> might be preferable option. 
		</div>
		
		<div className="redbox">
			<label className="redbox-label">
				<input type="checkbox" onClick={()=>{setEdit1(!edit1)}} label="Edit" />
				Edit
			</label>
			<ReferenceArrayInput source="type" reference="apps">
				<CheckboxGroupInput disabled={!edit1} optionText="name" />
			</ReferenceArrayInput>

		  <NumberInput disabled={!edit1}
			source="users"
			validate={required()}
			resettable
		  />
		  
		  <NumberInput disabled={!edit1}
			label="Offline interval (days)"
			source="offint"
			validate={required()}
			defaultValue={7}
		  />
				
			<TextInput source="hwid" disabled={!edit1} resettable label="Hardware ID" validate={hwkeyValidityCheck} />
		
			<ReferenceInput source="client" reference="clients" debounce={0}>
				<AutocompleteInput debounce={0} disabled={!edit1} optionText="name" />
			</ReferenceInput>		
					
			<DateInput disabled={!edit1}
			source="expiration"
			validate={required()}
			options={{
			  format: 'dd/MM/yyyy',
			  ampm: false,
			  clearable: true,
			}}
			/>
		</div>
      <BooleanInput source="enabled" />
	  <TextInput source="person" label="Contact person" />
	  <RichTextInput source="description" label="Description (for admin only)" />
    </SimpleForm>
	<style>
	{`
		.redbox	{
			border: 2px solid red;
			padding: 30px 20px 0px 20px;
			margin: 10px 0px;
		    border-radius: 5px;
			position: relative;
			display: inline-grid;
		}
		.redbox-label {
			position: absolute;
			top: 0px;
			right: 0px;
			background: red;
			padding: 0px 3px;
		}
	`}
	</style>
  </Edit>
) };

export const LicenseShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>      
	  <TextField source="sn" label="Serial number" />
	  <DateField source="created" />
      <ColoredDateField source="expiration" />	  
	  
	  <RichTextField source="description" />     
	  
      <ReferenceField source="client" reference="clients">
		<TextField source="name" />
      </ReferenceField>
	  <ReferenceArrayField source="type" reference="apps">
		<SingleFieldList>
			<ChipField source="name" />
		</SingleFieldList>
	  </ReferenceArrayField>
	  <TextField source="person" label="Contact person" />      
	  
	  <BooleanField source="enabled" />
      <TextField source="users" />
	  <TextField source="offint" label="Offline interval (days)" /> 
	  
	  <HwkeyField style={{wordBreak : "break-all"}} source="hwid" label="Hardware ID" />
	  
	  <LicenseField source="token" label="License token" />	  
    </SimpleShowLayout>
	<style>
	{`
	.MuiStack-root.ra-field {
		background: #EEE;
		border-radius: 10px;
		padding: 10px;
	}
	.RaSimpleShowLayout-stack {
		flex-flow: row wrap;
	}
	.RaSimpleShowLayout-stack>span {
		width: 100%;
	}
	.RaSimpleShowLayout-stack>span.ra-field-sn {
		margin: 0;
		margin-top: 8px;
	}
	.RaSimpleShowLayout-stack>span.ra-field-users,
	.RaSimpleShowLayout-stack>span.ra-field-offint,
	.RaSimpleShowLayout-stack>span.ra-field-enabled,
	.RaSimpleShowLayout-stack>span.ra-field-client,
	.RaSimpleShowLayout-stack>span.ra-field-type,
	.RaSimpleShowLayout-stack>span.ra-field-person,
	.RaSimpleShowLayout-stack>span.ra-field-sn,
	.RaSimpleShowLayout-stack>span.ra-field-created,
	.RaSimpleShowLayout-stack>span.ra-field-expiration 	{
		width: calc(33.333% - 10px);
	}
	.RaSimpleShowLayout-stack>span.ra-field-enabled,
	.RaSimpleShowLayout-stack>span.ra-field-users,
	.RaSimpleShowLayout-stack>span.ra-field-client,
	.RaSimpleShowLayout-stack>span.ra-field-type,
	.RaSimpleShowLayout-stack>span.ra-field-sn,
	.RaSimpleShowLayout-stack>span.ra-field-created {
		margin-right: 15px;
	}
	`}
	</style>
  </Show>
);
