import React from 'react';
import {
  Admin, Resource, fetchUtils,
} from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import jsonServerProvider from 'ra-data-json-server';
// import { createMuiTheme } from '@material-ui/core/styles';
import czechMessages from 'ra-language-czech';
import englishMessages from 'ra-language-english';
/*import ChatIcon from '@material-ui/icons/Chat';
import AccountIcon from '@material-ui/icons/AccountBox';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';*/

import ChatIcon from '@mui/icons-material/Chat';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ExtensionIcon from '@mui/icons-material/Extension';

import authProvider from './authProvider';

import {
  LicenseList, LicenseCreate, LicenseShow, LicenseEdit,
} from './licenses';
import {
  UserList, UserCreate, UserEdit, UserShow,
} from './users';
import {
  ClientList, ClientCreate, ClientEdit, ClientShow,
} from './clients';
import {
  AppsList, AppsCreate, AppsEdit, AppsShow,
} from './apps';
import Settings from './settings';

const messages = {
  cs: czechMessages,
  en: englishMessages,
};
const i18nProvider = polyglotI18nProvider((locale) => messages[locale], 'en');

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};
const dataProvider = jsonServerProvider(Settings.SERVER, httpClient);

messages.cs.resources = {
  users: { name: 'Uživatelé' },
  clients: { name: 'Klienti' },
  licenses: { name: 'License' },
  apps: { name: 'Aplikace' },
};

const App = () => (
  <Admin dataProvider={dataProvider} i18nProvider={i18nProvider} authProvider={authProvider}>
    <Resource name="licenses" edit={LicenseEdit} list={LicenseList} create={LicenseCreate} show={LicenseShow} icon={ChatIcon} />
    <Resource name="clients" list={ClientList} edit={ClientEdit} create={ClientCreate} show={ClientShow} icon={BusinessCenterIcon} />
	<Resource name="apps" list={AppsList} edit={AppsEdit} create={AppsCreate} show={AppsShow} icon={ExtensionIcon} />
    <Resource name="users" list={UserList} edit={UserEdit} create={UserCreate} show={UserShow} icon={AccountCircleIcon} />
  </Admin>
);

export default App;
