import React from 'react';
import {
  List, Datagrid, TextField, RichTextField, Edit,
  Show,
  EmailField,
  SimpleShowLayout,
  Create,
  SimpleForm,
  TextInput,
  ShowButton,
  ReferenceInput,
  AutocompleteInput,
  Filter,
  Pagination,
  required,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';

const AppsFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="Name" source="id" reference="apps" allowEmpty alwaysOn>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

const AppsPagination = (props) => (
  <Pagination rowsPerPageOptions={[50, 100, 200, 500]} {...props} />
);

export const AppsList = (props) => (
  <List
    {...props}
    filters={<AppsFilter />}
    pagination={<AppsPagination />}
    perPage={50}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <ShowButton />
    </Datagrid>
  </List>
);

export const AppsEdit = (props) => (
  <Edit {...props} mutationMode="pessimistic">
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="name" validate={required()} disabled={true} />
      <RichTextInput source="description" />
    </SimpleForm>
  </Edit>
);

export const AppsCreate = (props) => (
  <Create {...props} mutationMode="pessimistic">
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <RichTextInput source="description" />
    </SimpleForm>
  </Create>
);

export const AppsShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <RichTextField source="description" />
    </SimpleShowLayout>
  </Show>
);
